/**
    Video
*/

import EventBus from './../wirebox/event-bus'
import jQuery from 'jquery'
import './../wirebox/components/cached-script'

class Video {

    constructor (players) {
        this.players = players
        this.bindEvents()
    }
    
    run () {
        jQuery.cachedScript('https://cdn.jwplayer.com/libraries/AiwKTSuE.js', {
            complete: this.initPlayer.bind(this)
        })
    }

    initPlayer () {
        
        //Mobile
        /*if (window.matchMedia('(max-width: 991px)').matches) {
            
            let
                player,
                parents
            
            this.players.each((index, elem) => {
                
                player = jQuery(elem)
                parents = player.parents('.iframe-video')
                
                if (parents.length > 0) {
                    parents.remove()
                } else {
                    player.remove()
                }
                
            })
            
            return
            
        }*/
        
        //Desktop
        
        let jw
        
        this.players.each((index, elem) => {
            
            let player = jQuery(elem)
            
            if (!player.attr('src')) {
                return
            }
            
            jw = jwplayer(elem)

            jw
                .setup({
                    autostart:jQuery(elem).hasClass('no-autoplay') ? false : 'viewable',
                    controls: jQuery(elem).hasClass('show-controls') ? true : false,
                    file: player.attr('src'),
                    image: jQuery(elem).hasClass('has-poster') ? player.data('poster') : '',
                    mute: false,
                    repeat: true,
                    stretching: 'fill',
                })
                .on('play', () => {
                    if (!player.data('video-started')) {
                        jQuery(player.data('poster')).fadeOut()
                        player.data('video-started', true)
                    }
                })
                
        })
        
    }

    bindEvents () {
        
        //Run component
        EventBus.subscribe('run-modules', this.run.bind(this))
        
    }

}

EventBus.subscribe('init-modules', () => {
    let videoPlayers = jQuery('.jw-player')

    if (videoPlayers.length > 0) {
        new Video(videoPlayers)
    }
})

export default Video
