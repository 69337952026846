/**
 * Detect whether the User is browsing on a touch device
*/

import EventBus from '../../event-bus.js'

const DetectTouchDevice = {
    
    run() {

        let isTouchDevice =
            'ontouchstart' in window ||
            navigator.MaxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        
        
        isTouchDevice ? EventBus.publish('isTouchDevice') : EventBus.publish('isNotTouchDevice')
        
    }

}

EventBus.subscribe('modules-initialised', DetectTouchDevice.run)

export default DetectTouchDevice