/**
 * jQuery method to cache getScript() urls
*/

import EventBus from './../event-bus.js'
import jQuery from 'jquery'

const CachedScript = {
    
    init() {

        jQuery.cachedScript = (url, options) => {
 
            options = jQuery.extend(options || {}, {
                dataType: 'script',
                cache: true,
                url: url
            })
         
            return jQuery.ajax(options)
          
        }
        
    }
    
}

EventBus.subscribe('init-modules', CachedScript.init)

export default CachedScript