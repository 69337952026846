import EventBus from '../wirebox/event-bus.js'
import 'slick-carousel' 
import jQuery from 'jquery'

const StaffCarousel = {
    
    run() {

        const staffCarousels = jQuery('.staff-carousel-slides')

        staffCarousels.each((i, elem) => {

            jQuery(elem).slick({
                slidesToShow: 6,
                slidesToScroll: 2,
                arrows: true,
                variableWidth: false,
                draggable: false,
                prevArrow: jQuery('#staff-carousel-controls .prev-arrow'),
                nextArrow: jQuery('#staff-carousel-controls .next-arrow'),
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    }
                ]
            })
        })

    }

}

EventBus.subscribe('run-modules', StaffCarousel.run)

export default StaffCarousel 
