/**
 * Scroll to element upon clicking anchored hyperlink (requires utilities/scroll-to-target.js)
*/

import EventBus from './../event-bus.js'
import './../utilities/scroll-to-target.js'
import jQuery from 'jquery'

const AnchorScroll = {
    
    init() {

        jQuery(document).on('click', 'a[href^="#"]', (event) => {
            
            let clicked = jQuery(event.target);

            //Ignore empty anchors
            let targetSel = clicked.attr('href')
            if (targetSel == '#') {
                event.preventDefault()
                return
            }
            
            //Ignore bootstrap toggles
            if (clicked.data('toggle')) {
                return
            }
            
            let target = jQuery(targetSel)
            if (target.length === 0) {
                return
            }
            
            var options = {target: target}
            
            let header = clicked.data('header')
            if (header) {
                options.offset = jQuery(header).outerHeight()
            }
            
            event.preventDefault()
            EventBus.publish('scroll-to-target', options)
            
        })
        
    }
    
}

EventBus.subscribe('init-modules', AnchorScroll.init)

export default AnchorScroll