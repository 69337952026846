/**
 * Navigation
*/

import EventBus from './../wirebox/event-bus.js'
import jQuery from 'jquery'

class ToggleShowContent {

    constructor() {
        

        this.isTouchDevice = false
   
        this.breakPoint = typeof navigationBreakpoint == 'undefined' ? 991 : navigationBreakpoint

        this.isMobileBreakpoint = null

        this.bindEvents()
    }

    elementClick(e) {

        let
            clicked = jQuery(this),
            target = jQuery(clicked.data('target'))
            
            e.preventDefault()
            clicked.addClass('active').siblings().removeClass('active')
            target.addClass('active').siblings().removeClass('active')
        
    }

    elementHoverIn() {
        let element = jQuery(this),
            target = jQuery(element.data('target'))
        element.addClass('active')
        target.addClass('active').siblings().removeClass('active')
        element.siblings().removeClass('active')

    }

    update() {

        const
           doc = jQuery(document),
           html = jQuery('html')
        
        this.isMobileBreakpoint ? html.addClass('wb-mobile').removeClass('wb-desktop') : html.removeClass('wb-mobile').addClass('wb-desktop')
        
       
        if (this.isTouchDevice || this.isMobileBreakpoint) {
           
            if (html.hasClass('wb-touch')) {
                return
            }
            
            html.addClass('wb-touch').removeClass('wb-notouch')
            
            doc.on('touchstart', '.toggle-show-content', this.elementClick)
            
            
        }
        else {
            
            if (html.hasClass('wb-notouch')) {
                return
            }
           
            html.removeClass('wb-touch').addClass('wb-notouch')
            
            doc.on('mouseenter', '.toggle-show-content', this.elementHoverIn)

            doc.off('click', '.toggle-show-content', this.elementClick)

            
            
        }

    }

    setMobileBreakpoint() {

        let oldBreakpoint = this.isMobileBreakpoint
        this.isMobileBreakpoint = window.matchMedia('(max-width: ' + this.breakPoint + 'px)').matches
        
        if (this.isMobileBreakpoint !== oldBreakpoint) {
            this.update()
        }

    }
   
    bindEvents() {
            EventBus.subscribe('isTouchDevice', () => { 
            this.isTouchDevice = true
            this.setMobileBreakpoint()
       })

        EventBus.subscribe('isNotTouchDevice', () => { 
            this.isTouchDevice = false
            this.setMobileBreakpoint()
       })
        jQuery(window).resize(this.setMobileBreakpoint.bind(this))
    }

}

EventBus.subscribe('init-modules', () => {
    let elements = jQuery('.toggle-show-content');

    if (elements.length > 0) {    
        new ToggleShowContent()
    }
})

export default ToggleShowContent