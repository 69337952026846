import EventBus from '../wirebox/event-bus.js'
import 'slick-carousel' 
import jQuery from 'jquery'

const BrandsCarousel = {
    
    run() {

        const brandsCarousels = jQuery('.brands-carousel-slides')
        const brandsDescriptionCarousels = jQuery('.brands-description-carousel')

        brandsCarousels.each((i, elem) => {

            jQuery(elem).on('beforeChange', (event, slick) => {

                    let active= jQuery(elem).find('.slick-active'),
                    activePlayer = active.find('.jwplayer').attr('id')

                    if (activePlayer) {

                        jwplayer(activePlayer).pause()

                    }
                    

            }).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: false,
                autoplay: false,
                infinite: false,
                variableWidth: false,
                rows: 0,
                draggable: false,
                prevArrow: jQuery('#brands-carousel-controls .prev-arrow'),
                nextArrow: jQuery('#brands-carousel-controls .next-arrow'),
                asNavFor: '.brands-description-carousel'
            })
        })


        brandsDescriptionCarousels.each((i, elem) => {
            jQuery(elem).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                autoplay: false,
                infinite: false,
                variableWidth: false,
                rows: 0,
                draggable: false
            })
        })
    }

}

EventBus.subscribe('run-modules', BrandsCarousel.run)

export default BrandsCarousel 
