
import Bowser from "bowser"
import EventBus from './../wirebox/event-bus.js'
import jQuery from 'jquery'


const AddBrowserClass = {


	run() {
		const browser = Bowser.getParser(window.navigator.userAgent)

		
		let 
			html = jQuery('html'),
			addClass,
			browserInfo = browser.getBrowser(),
			name = browserInfo.name,
			version = browserInfo.version


			switch(name) {

				case 'Internet Explorer': {

					addClass = 'ie-' + version.split('.')[0]

					break;

				}

			}



			html.addClass(addClass)
	}
}

EventBus.subscribe('run-modules', AddBrowserClass.run)

export default AddBrowserClass