//jQuery
import jQuery from 'jquery'

//Bootstrap
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/collapse'

//Wirebox core
import EventBus from './wirebox/event-bus.js'


//Wirebox components
import './wirebox/components/anchor-scroll.js'


//Wirebox utilities
import './wirebox/utilities/touch-device/detection.js'

//Components

import './components/count-to.js'
import './components/toggle-show-content.js'
import './components/video.js'
import './components/hero-banner.js'
import './components/brands-carousel.js'
import './components/mobile-nav.js'
import './components/staff-carousel.js'

// Utilities
import './utilities/add-browser-class.js'
import './utilities/fade-in-section.js'


//Add jQuery to global window object
global.jQuery = jQuery

//Run modules
EventBus.run()
