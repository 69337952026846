import EventBus from '../wirebox/event-bus.js'
import jQuery from 'jquery'

class MobileNav {
    
    /**
     * Construct component
     * @param menu
    */
    constructor(menu) {
        
        /**
         * Menu jQuery object
         * @type {object}
        */
        this.menu = menu
        
        this.bindEvents()
        
    }
    
    /**
     * Menu showing
    */
    menuShowing() {
        jQuery('html').addClass('mobile-menu-showing')
    }

    /**
     * Close Menu
    */
    closeMenu() {
        if ( jQuery('html').hasClass('mobile-menu-showing') ) {
            this.menu.collapse('hide')
        }
    }
    
    /**
     * Menu showing
    */
    menuHiding() {
        jQuery('html').removeClass('mobile-menu-showing')
    }
    
    /**
     * Bind events
    */
    bindEvents() {
        
        //Menu showing
        this.menu.on('show.bs.collapse', this.menuShowing.bind(this))
        
        //Menu hiding
        this.menu.on('hide.bs.collapse', this.menuHiding.bind(this))

        // menu item click
        this.menu.on('click', 'a', () => {
            this.closeMenu()
        })
        
    }
    
}

EventBus.subscribe('init-modules',  () => {
    
    let menu = jQuery('#main-navigation')
    
    if (menu.length > 0) {
        new MobileNav(menu)
    }
    
})

export default MobileNav