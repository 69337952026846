/**
 * Scroll to element
*/

import EventBus from '../event-bus.js'
import jQuery from 'jquery'

const ScrollToTarget = {
    
    /**
     * Handle scroll
     * @param params
    */
    handle(params) {
        
        if (!params.hasOwnProperty('target') || params.target.length === 0) {
            return false
        }

        var position = params.target.offset().top
        
        //Option for including header height
        if (params.hasOwnProperty('offset')) {
            position -= params.offset
        }
        
        //Option for scrolling to the bottom of an element
        if (params.hasOwnProperty('bottom')) {
            position += params.target.outerHeight()
            position -= jQuery(window).height()
        }
        else if (params.hasOwnProperty('center')) {
            //Option for scrolling the element to the center of the page
            position += params.target.outerHeight() / 2
            position -= jQuery(window).height() / 2
        }

        //Perform scroll
        jQuery('html,body').stop().animate({
            scrollTop: position,
        }, params.hasOwnProperty('speed') ? params.speed : 'slow', params.hasOwnProperty('callback') ? params.callback : null)
        
    },

    /**
     * Bind events
    */
    bindEvents() {
        EventBus.subscribe('scroll-to-target', this.handle.bind(this))
    }
 
}

ScrollToTarget.bindEvents()

export default ScrollToTarget