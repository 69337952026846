/**
    Video
*/

import EventBus from './../wirebox/event-bus.js'
import jQuery from 'jquery'

class HeroBanner {
    constructor (banners) {
        if (typeof banners === 'undefined') {
            return
        }

        this.banners = banners
        this.bindEvents()
    }

    bindEvents () {
        this.setupBannerVideoEvents()
    }

    setupBannerVideoEvents () {
        if (typeof this.banners === 'undefined') {
            return
        }

        jQuery(this.banners).each((index, banner) => {
            let videoContainer = jQuery(banner).find('.iframe-video')

            if (videoContainer.length < 1 || !videoContainer.data('load-delay')) {
                return
            }

            let loadDelay = parseInt(videoContainer.data('load-delay'))

            setTimeout(() => {
                videoContainer.find('video')[0].muted = true
                videoContainer.fadeIn()
                videoContainer.find('video')[0].play()
                videoContainer.find('video')[0].muted = false
            }, loadDelay)
        })
    }

}

EventBus.subscribe('init-modules', () => {
    let banners = jQuery('.hero-banner')

    if (banners.length > 0) {
        new HeroBanner(banners)
    }
})

export default HeroBanner
