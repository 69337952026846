/**
 * Detect when element is in viewport and animate counter
*/

import EventBus from './../wirebox/event-bus.js'
import jQuery from 'jquery'


class CountTo {
        /**
     * Construct component
     * @param elements
    */
    constructor(elements) {
        
        /**
         * elements to detect
         * @type object
        */
        this.elements = elements
        

        
        this.bindEvents()
        
    }

    handleAnimation(element) {

        let target = element

        target.addClass('animation-started')

        if (target.hasClass('has-animated') || !target.text().match(/[0-9]+/)) {
            return
        }

        let targetNumber = parseInt(target.text().match(/[0-9]+/)[0])
        
        jQuery({ Counter: 0 }).animate({ Counter: targetNumber }, {
            duration: 2500,
            easing: 'swing',
            step: function () {
                let targetText = target.text().replace(/[0-9]+/, Math.ceil(this.Counter))
                target.text(targetText)
            },
            complete: function() {
                target.addClass('has-animated')
            }
        });

    }
    
    /**
     * Handle window scroll
    */
    handleScroll() {
        
        let
            isInViewport,
            element,
            target,
            targetTop,
            targetHeight,
            targetBottom,
            win = jQuery(window),
            viewportTop = win.scrollTop(),
            viewportBottom = viewportTop + win.height()
        
        this.elements.each((i, domElement) => {
            
            element = jQuery(domElement)
            
            //Cancel if element is not visible
            if (!element.is(':visible')) {
                return
            }
        
            
            target = element
            
            targetTop  = target.offset().top
            targetHeight = target.height()
            targetBottom = targetTop + target.outerHeight

            //Determine whether the element is in viewport
            isInViewport = (viewportTop >= parseInt(targetTop - win.height()))

            if (isInViewport) {
                
                if (target.hasClass('animation-started')) {
                    return
                } else {
                    this.handleAnimation(target)
                }

                
            } else {
                target.removeClass('has-animated')
            }
            
        })
    
    }
    
    /**
     * Bind events
    */
    bindEvents() {
        EventBus.subscribe('run-modules', this.handleScroll.bind(this))
        jQuery(document).scroll(this.handleScroll.bind(this))
    }
    
}

EventBus.subscribe('init-modules',  () => {
    
    let elements = jQuery('.count-to')
    
    if (elements.length > 0) {
        new CountTo(elements)
    }
    
})

export default CountTo
