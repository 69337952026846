/**
 * Detect when element is in viewport and fade in
*/

import EventBus from './../wirebox/event-bus.js'
import jQuery from 'jquery'


class FadeInSection {
    
    /**
     * Construct component
     * @param elements
    */
    
    constructor(elements) {
        
        /**
         * elements to detect
         * @type object
        */
        this.elements = elements
        

        
        this.bindEvents()
        
    }
    
    /**
     * Handle window scroll
    */
    
    handleScroll() {
        
        let 
            addClass,
            isInViewport,
            element,
            target,
            targetTop,
            targetHeight,
            targetBottom,
            win = jQuery(window),
            viewportTop = win.scrollTop()
        
        this.elements.each((i, domElement) => {
            
            element = jQuery(domElement)
            
            //Cancel if element is not visible
            if (!element.is(':visible')) {
                return
            }

            if (jQuery('html').hasClass('ie-11')) {
                return
            }
        
            
            target = element
            
            targetTop  = target.offset().top
            targetHeight = target.height()
            targetBottom = targetTop + target.outerHeight
            
            //Set default class to add to element when scrolled in to position
            if (!addClass) {
                addClass = 'is-visible'
            }
            
            //Determine whether the element is in viewport
            isInViewport = targetTop < viewportTop + win.height() && targetTop +  targetHeight > viewportTop

            
            // add class when scrolled into viewport
            if (isInViewport) {
                element.addClass(addClass)
                
            }
            
            // remove class when scrolled out of viewport
            else {
                element.removeClass(addClass)
                
                
            }
            
        })
    
    }
    
    /**
     * Bind events
    */
    bindEvents() {
        EventBus.subscribe('run-modules', this.handleScroll.bind(this))
        jQuery(document).scroll(this.handleScroll.bind(this))
    }
    
}

EventBus.subscribe('init-modules',  () => {
    
    let elements = jQuery('.fade-in-section')
    
    if (elements.length > 0) {
        new FadeInSection(elements)
    }
    
})

export default FadeInSection

